import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { WrapStyled } from '@atlas/ui-library/components/layout/Wrapper/style';
import { TitleContainer } from '@atlas/ui-library/components/atoms/FontScale/Title/style';
import { breakpoints, media } from '@atlas/ui-library/lib/breakpoints';

const jumboHeightDesktop = 300;
const jumboHeightMobile = 150;

function getFeatureImageHeight() {
	const { PHONE_LANDSCAPE_MIN_WIDTH, DESKTOP_MIN_WIDTH } =
		breakpoints;
	const phoneLandscapeMinValue = PHONE_LANDSCAPE_MIN_WIDTH.replace(
		'px',
		'',
	);
	const desktopMinValue = DESKTOP_MIN_WIDTH.replace('px', '');
	return `clamp(${jumboHeightMobile}px, calc(${jumboHeightMobile}px 
		+ (${jumboHeightDesktop} - ${jumboHeightMobile}) 
		* ((100vw - ${PHONE_LANDSCAPE_MIN_WIDTH}) 
		/ (${desktopMinValue} - ${phoneLandscapeMinValue}))), 
		${jumboHeightDesktop}px)`;
}

/**
 * Title / Text / CTA block wrapper.
 */
const JumbotronTextBox = styled.div<{
	$hasTextBoxBackground: boolean;
}>`
	z-index: 1;
	a {
		margin: var(--spacing-_16) var(--spacing-_16) 0;
		${media.smMax`
			width: -webkit-fill-available;
			width: -moz-available;
		`}
		${media.mdMin`
			margin: var(--spacing-_24) auto 0;
		`}
	}

	h1:not(:first-child) {
		margin-top: var(--spacing-_16);
		${media.mdMin`
			margin-top: var(--spacing-_24);
		`}
	}

	// Active Text Block Background styling.
	${({ $hasTextBoxBackground }) =>
		$hasTextBoxBackground && JumbotronTextBoxActive}
`;

const JumbotronTextBoxActive = css`
	background-color: white;
	max-width: calc(296px + 16px + 16px);
	align-self: center;
	padding: var(--spacing-_16);
	${TitleContainer} {
		margin-bottom: var(--spacing-_16);
	}
	p {
		max-width: 296px;
		margin: auto;
	}
	${media.mdMin`
		max-width: calc(718px + 40px + 40px);
		padding: var(--spacing-_24) var(--spacing-_40) var(--spacing-_32);
		p {
			max-width: 718px;
		}
	`}
`;

const ImageFilter = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: color-mix(
		in srgb,
		var(--color-basic-jumbotron-_1) var(--opacity-jumbotron-_1),
		var(--color-basic-jumbotron-_2) var(--opacity-jumbotron-_2)
	);
	z-index: 1;
`;

const jumboVariants = theme('variant', {
	ROW: css`
		min-height: 512px;
		& > .wrap {
			flex-direction: row;
		}
		.imageContainer {
			width: 130px;
			z-index: 1;
		}
		${JumbotronTextBox} {
			max-width: 650px;
			text-align: left;
		}
		${media.mdMin`
			flex-direction: row;
			text-align: left;
			.imageContainer {
				width: unset;
				margin-right: 30px;
			}
		`}
	`,
	RIGHTBOX: css`
		min-height: 260px;
		& > .wrap {
			flex-direction: row;
			width: 100vw;
		}
		.imageContainer {
			display: none;
		}
		${JumbotronTextBox} {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			padding: 0 var(--spacing-_24);
			margin: 0;
			height: 260px;
			width: 100vw;
			background-color: rgba(255, 255, 255, 0.73);
			h1 {
				color: color-mix(
					in srgb,
					var(--color-text-onSurface)
						var(--opacity-emphasis-surface-high),
					transparent
				);
				font-size: var(--font-title-size-M-laptop);
				font-family: var(--font-title-family);
				text-align: left;
				font-weight: 100;
			}
			a {
				width: 100%;
				margin: 0;
			}
		}
		${media.mdMin`
			${JumbotronTextBox} {
				margin-left: 58vw;
				width: 25vw;
			}	
		`}
	`,
	DEFAULT: css`
		min-height: 418px;
		padding-top: 32px;
		justify-content: flex-start;

		${media.mdMin`
			padding-top: 96px;
		`}

		${media.lgMin`
			padding-top: 0;
			justify-content: center;
			min-height: 418px;
		`}

		.imageContainer {
			display: none;
		}
	`,
	FEATURED_IMAGE: css`
		// clamp calc https://websemantics.uk/tools/responsive-font-calculator/
		min-height: ${jumboHeightMobile}px;
		height: ${getFeatureImageHeight()};
		margin: 0 calc(-1 * var(--spacing-_16));
		${media.mdMin`
				margin: 0 calc(-1 * ((100vw - 100%) / 2)); // no padding above mobile landscape, use 100% width of the wrapper
			`}
		.backgroundImage {
			object-position: bottom;
		}
		.imageContainer {
			display: none;
		}
	`,
	SPLIT_50: css`
		min-height: auto;
		padding: 10px 20px;
		& > ${WrapStyled} {
			width: 100%;
			${media.mdMin`
				flex-direction: row;
			`}
		}
		.imageContainer {
			width: 50%;
			margin: auto;
			margin-right: 15px;
			text-align: center;
		}
		${JumbotronTextBox} {
			width: 50%;
			text-align: start;
		}
	`,
});

/**
 * Main Jumbotron component.
 */
const JumbotronSC = styled.div`
	position: relative; // Needed for BG-Image. If commented it throws a warning.
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	.backgroundImage {
		object-fit: cover;
		object-position: 47%;
		z-index: 0;
		${media.mdMin`
			object-position: 0% 42%;
		`}
	}
	${jumboVariants}
`;

export { JumbotronSC, JumbotronTextBox, ImageFilter };
