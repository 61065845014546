import { IContentLoaderProps } from 'react-content-loader';
import { BodySkeletonSC } from './style.skeleton';

export const BodySkeleton = ({
	lines = 3,
	...props
}: { lines?: number } & IContentLoaderProps) => {
	return (
		<BodySkeletonSC
			speed={2}
			width="100%"
			height={lines * 26}
			backgroundColor="#f3f3f3"
			foregroundColor="#ece0e0"
			{...props}>
			{Array(lines)
				.fill('')
				.map((_: string, index: number) => {
					return (
						<rect
							key={index}
							x="0"
							y={index * 26}
							width="100%"
							height={20}
						/>
					);
				})}
		</BodySkeletonSC>
	);
};
