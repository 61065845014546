import { useState } from 'react';
import { useTranslations } from 'next-intl';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';
import { base64Encode } from '@atlas/statics/lib/base64Encoder';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { breakpoints } from '@atlas/ui-library/lib/breakpoints';
import type { Country } from './types';

export const useCountryCards = () => {
	const { data: crmData, isLoading, isError } = useCrmData();

	const t = useTranslations();

	const countries = crmData!.productType.eligibleCountries;

	const sortedCountries = [...countries].sort((a, b) => {
		const nameA = t(base64Encode(a.name)).toUpperCase();
		const nameB = t(base64Encode(b.name)).toUpperCase();
		return nameA.localeCompare(nameB);
	});

	const [dataFiltered, setFilterContext] =
		useState<Country[]>(sortedCountries);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const width = useWindowWidth();

	if (isLoading || isError) {
		return { isError, isLoading };
	}
	const isFiltered =
		sortedCountries.length > 0 &&
		dataFiltered.length < sortedCountries.length;

	const hasFilterResults = dataFiltered.length > 0;

	const shouldExpandFilter =
		!isCollapsed || dataFiltered.length === 0 || isFiltered;

	/**
	 * Toggles country list view between collapsed / entire list.
	 * @listens Button.onClickEvent - View all / View Less
	 * @fires CountryCards#setIsCollapsed - State setter
	 */
	const toggle = ({ target }: any) => {
		setIsCollapsed((wasCollapsed) => !wasCollapsed);
		if (!isCollapsed) {
			const targetTopPosition =
				target.parentElement.getBoundingClientRect().top +
				(window.scrollY - 400);
			scrollTo(0, targetTopPosition);
		}
	};

	const isNotMobile =
		width >= parseInt(breakpoints.TABLET_PORTRAIT_MIN_WIDTH);

	return {
		dataFiltered,
		setFilterContext,
		countries,
		isNotMobile,
		isFiltered,
		hasFilterResults,
		shouldExpandFilter,
		isCollapsed,
		toggle,
	};
};
