/** ******************************************************************************
 * This code search for varnames between brackets {{variableName}} and replace them with API final value.
 * NOTE: Search given a {{variableName}} in every existing level.
 *
 *                              /\
 *                             /  \
 *                            |    |
 *                          --:'''':--		"Abracadabra ...
 *                            :'_' :			... mapea variable con palabra ..."
 *                            _:"":\___
 *             ' '      ____.' :::     '._
 *            . *=====<<=)           \    :
 *             .  '      '-'-'\_      /'._.'
 *                              \====:_ ""
 *                             .'     \\
 *                            :       :
 *                           /   :    \
 *  (plof!)                 :   .      '.
 *          ,. _            :  : :      :
 *       '-'    ).          :__:-:__.;--'
 *     (        '  )        '-'   '-'
 *  ( -   .00.   - _
 * (    .'  _ )     )
 * '-  ()_.\,\,   -
 ****************************************************************************** */
import { CrmData } from '@atlas/statics/services/AppContext';
import type { Font } from '@atlas/statics/lib/types/_fonts';
import { getDynamicVarsMap } from '@atlas/statics/lib/getDynamicVarsMap';
import { FILTER_DELIMITER, FILTER_COLLECTION } from './config';

export const replaceDynamicVarsWithValues = (
	sourceString: string,
	variableMap: Record<string, string>,
): string => {
	Object.keys(variableMap).forEach((variableName) => {
		const regex = new RegExp(
			`{{(\\s*)?${variableName}(\\s*${FILTER_DELIMITER}\\s*)?(${FILTER_COLLECTION.join(
				'|',
			)})?(\\s*)?}}`,
			'g',
		);
		sourceString = sourceString.replace(
			regex,
			variableMap[variableName],
		);
	});
	return sourceString;
};

export async function replaceVars(
	sourceString: string,
	crmData: CrmData,
	font?: Font,
	locale?: string,
): Promise<string> {
	try {
		const dynamicVariablesMap = await getDynamicVarsMap({
			sourceString,
			crmData,
			font,
			locale,
		});
		return replaceDynamicVarsWithValues(
			sourceString,
			dynamicVariablesMap,
		);
	} catch (err: unknown) {
		throw new Error(err as string, { cause: err });
	}
}
