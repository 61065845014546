import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { media } from '@atlas/ui-library/lib/breakpoints';
import {
	LocaleList,
	LocaleSwitcherContainer,
} from '@atlas/ui-library/components/molecules/LocaleSwitcher/style';

const NAVBAR_HEIGHT = '64px';

const hoverBottomBorder = `
	content: '';
	height: 2px;
	width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: var(--color-primary-_500);
`;

export const MenuNavList = styled.ul`
	appearance: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: var(--spacing-_24);
	${media.smMax`
		justify-content: space-around;
		align-items: center;
	`}
`;

export const MenuNavElement = styled.li`
	appearance: none;
	list-style: none;
	height: ${NAVBAR_HEIGHT};
	padding: 0;
	text-align: center;

	a {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 12px;
		text-decoration: none;
		p {
			margin: 0;
		}
	}

	.current:after {
		${hoverBottomBorder}
	}

	${media.mdMin`
		text-align: -webkit-match-parent;
		position: relative;
		&:hover {
			background-color: var(--color-primary-_100);
			& > a {text-decoration: none;}
			& > a:after,
			& > ${LocaleSwitcherContainer}:after {
				${hoverBottomBorder}
			}
		}
		a {
			text-decoration: none;
			padding: 0 16px;
		}
	`}
`;

const variant = theme('variant', {
	DEFAULT: `
		background-color: var(--color-primary-_50);
	`,
	DARK: css`
		background-color: var(--color-primary-_900);
		.current {
			background-color: color-mix(
				in srgb,
				white var(--opacity-overlay-pressed),
				var(--color-primary-_900)
			);
			&:after {
				background-color: var(--color-primary-_900);
			}
		}
		${MenuNavElement}, ${LocaleList} {
			&:hover {
				background-color: color-mix(
					in srgb,
					black var(--opacity-overlay-hover),
					var(--color-primary-_900)
				);
				a:after,
				${LocaleSwitcherContainer}:after {
					background-color: var(--color-primary-_100);
				}
			}
		}
		${LocaleList} {
			background-color: var(--color-primary-_900);
		}
	`,
});

export const NavbarSC = styled.div`
	height: ${NAVBAR_HEIGHT};
	display: flex;
	background-color: var(--color-primary-_50);
	align-items: center;
	width: 100%;
	${variant}
	${media.smMax`
		.wrap {
			padding: 0;
		}	
	`}
`;
