import { getCrmData } from '@atlas/statics/hooks/useCrmData';
import { localesByRegion } from '@atlas/statics/i18n/config';

export const LocaleParser = {
	short(localeLongCode: string) {
		if (localeLongCode === '')
			throw new Error("Locale can't be an empty string");
		const splitIndex = localesByRegion.includes(
			localeLongCode.toLowerCase(),
		)
			? 1
			: 0;
		const localeShortCode = localeLongCode.split(/-|_/)[splitIndex];
		return localeShortCode.toLowerCase();
	},

	async long(localeShortCode: string) {
		if (localeShortCode === '')
			throw new Error("Locale can't be an empty string");

		const crmData = await getCrmData().catch((err) => {
			throw new Error('Failed to fetch CRM data', { cause: err });
		});

		if (!crmData) {
			throw new Error('CRM data not found');
		}

		const {
			productType: { availableLanguages },
		} = crmData;

		const [{ locale }] = availableLanguages.filter(
			({ locale }) => this.short(locale) === localeShortCode,
		);

		return locale;
	},
};
