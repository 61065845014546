import styled, { css } from 'styled-components';
import { media } from '@atlas/ui-library/lib/breakpoints';

const flexCenter = css`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const displaySwitch = css`
	display: block;
	z-index: 2;
`;

const mobileSwitch = css`
	position: relative;
	top: 16px;
	background-color: var(--color-primary-_900);
`;

export const LocaleList = styled.ul<{
	$isOpen: boolean;
	$isMobile: boolean;
}>`
	cursor: pointer;
	display: none;
	position: relative;
	left: 0;
	margin: 0;
	padding: 0;
	background: var(--color-primary-_50);
	z-index: 0;
	&:hover {
		background-color: var(--color-primary-_100);
		a {
			text-decoration: none;
		}
	}
	${({ $isOpen }) => ($isOpen ? displaySwitch : undefined)}
	${({ $isMobile }) => ($isMobile ? mobileSwitch : undefined)}
`;

export const LocaleItem = styled.li`
	${flexCenter}
	padding: var(--spacing-_16);
	position: absolute;
	width: max-content;
	min-width: 100%;
	right: 0;
	background: var(--color-primary-_50);
	${media.mdMin`padding: var(--spacing-_16) 0;`}
`;

export const CurrentLocale = styled.div`
	${flexCenter}
	cursor: pointer;
	text-decoration: none;
	margin: 0;
	padding: 0 var(--spacing-_16) 0;
	height: 100%;
`;

export const LocaleSwitcherContainer = styled.div`
	position: relative;
	align-content: center;
	height: inherit;
	p {
		margin: 0;
	}
`;
